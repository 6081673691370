<template>
  <div class="content">
    <div class="container_body">
      <article class="link_container_body">
        <h1>本站声明</h1>
        <div class="article-content">
          <h2>一、版权声明</h2>
          <p>
            {{
              systemName
            }}所有内容均由不动产登记事务中心及其组成部门和直属机构提供，包括：文字、图片、声像、软件、标识、域名、图表和附件等。
          </p>
          <h2>二、隐私保护</h2>
          <p>
            1、本网站承诺将使用相应技术，对您所提供的个人资料进行严格的管理和保护，防止个人资料丢失、被盗用或遭窜改。
          </p>
          <p>
            2、在未经你同意和确认之前，除国家法律、地方法规和政府规章规定，以及维护{{
              systemName
            }}合法权益之外，本网站保证不会把这些个人资料提供给任何机构和个人，但是会根据工作需要与政府有关部门共同使用。
          </p>
          <h2>三、免责声明</h2>
          <p>
            1、本网站对上所有由第三方提供的信息、内容和服务的质量（包括下载后的数据的质量和即时性，以及对取自于本网站的数据的任何分析结果），不提供明示或暗示的担保。本网站对使用上述信息、内容和服务所造成的任何损失不承担责任，包括直接损失和间接损失。
          </p>
          <p>
            2、任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营及其他之不可抗力原因而造成的资料泄露、丢失、被盗用或被篡改等，本网站将采取必要措施尽力减少用户的损失，但本网站对此不承担任何责任。
          </p>
          <p>
            3、由于与本网站链接的其它网站所造成之个人资料泄露及由此而导致的任何法律争议和后果，请谨慎点击，本网站对此不承担任何责任。
          </p>
          <p>
            4、由于您将用户密码告知他人或与他人共享注册账户，由此导致的任何个人信息的泄露的，本网站对此不承担任何责任。
          </p>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      systemName: window.CONFIG.APP_INFO.systemName
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/link.scss';
</style>
